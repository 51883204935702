import React from 'react'

import Layout from '../components/layout'

const NotFoundPage = () => (
    <Layout>
        <div className="container">
            <h1>Sorry, we couldn't find that</h1>
        </div>
    </Layout>
)

export default NotFoundPage
